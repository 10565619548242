/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocationSystem } from '../models/LocationSystem';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LocationSystemService {

    /**
     * Get a list of `LocationSystem`s
     * @returns any A list of `LocationSystems`.
     * @throws ApiError
     */
    public static listLocationSystems({
page,
size,
filter,
sort,
}: {
/**
 * Page number
 */
page?: number,
/**
 * Number of items per page
 */
size?: number,
/**
 * A filter to apply to the results. Multiple filters can be applied by using the same parameter name multiple times. String must be URL encoded. String follows the format field,operator,value(s),required. Filters can be applied to associations by using `[associatedModel*].[attribute]` as field. If values is an array, which is allowed for e.g. the operators "in", "notIn" etc, the delimiter "|" must be used. "required" is allowed, if a deep filter (= filter on an association) is set. In this case, only entities that have an associated entity are returned. *model name should be written as in the API Response, meaning with lowercase first letter and if need be in plural. `required` is optional
 */
filter?: string,
/**
 * Sorts the results by the given field and order. The format is field:order. The order can be asc or desc. Multiple fields can be sorted by using a comma separated list.
 */
sort?: string,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/location-systems',
            query: {
                'page': page,
                'size': size,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Create a new `LocationSystem`
     * @returns LocationSystem A single `LocationSystem`.
     * @throws ApiError
     */
    public static createLocationSystem({
requestBody,
}: {
requestBody: LocationSystem,
}): CancelablePromise<LocationSystem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/location-systems',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a specific `LocationSystem`
     * @returns LocationSystem A single `LocationSystem`.
     * @throws ApiError
     */
    public static getLocationSystem({
id,
}: {
/**
 * ID of the `LocationSystem`
 */
id: string,
}): CancelablePromise<LocationSystem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/location-systems/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update a specific `LocationSystem`
     * @returns LocationSystem A single `LocationSystem`.
     * @throws ApiError
     */
    public static updateLocationSystem({
id,
requestBody,
}: {
/**
 * ID of the `LocationSystem`
 */
id: string,
requestBody: LocationSystem,
}): CancelablePromise<LocationSystem> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/location-systems/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a specific `LocationSystem`
     * @returns void 
     * @throws ApiError
     */
    public static deleteLocationSystem({
id,
}: {
/**
 * ID of the `LocationSystem`
 */
id: string,
}): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/location-systems/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get messages of a specific `LocationSystem`
     * @returns any A list of `Message`s.
     * @throws ApiError
     */
    public static getLocationSystemMessages({
id,
}: {
/**
 * ID of the `LocationSystem`
 */
id: string,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/location-systems/{id}/messages',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get messages of a specific Channel
     * @returns LocationSystem A single `LocationSystem`.
     * @throws ApiError
     */
    public static getLocationSystemChannelMessages({
id,
channel,
}: {
/**
 * ID of the `LocationSystem`
 */
id: string,
/**
 * Channel Name
 */
channel: string,
}): CancelablePromise<LocationSystem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/location-systems/{id}/channelMessages/{channel}',
            path: {
                'id': id,
                'channel': channel,
            },
        });
    }

    /**
     * Get live status of a specific `LocationSystem`
     * @returns any A list of `Message`s.
     * @throws ApiError
     */
    public static getLocationSystemLiveStatus({
id,
}: {
/**
 * ID of the `LocationSystem`
 */
id: string,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/location-systems/{id}/live-status',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update a status of a specific `LocationSystem`
     * @returns LocationSystem A single `LocationSystem`.
     * @throws ApiError
     */
    public static updateLocationSystemStatus({
id,
key,
state,
}: {
/**
 * ID of the `LocationSystem`
 */
id: string,
/**
 * Key of the Status
 */
key: string,
/**
 * State of the Status
 */
state: string,
}): CancelablePromise<LocationSystem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/location-systems/{id}/status/{key}/{state}',
            path: {
                'id': id,
                'key': key,
                'state': state,
            },
        });
    }

    /**
     * Run a flow for a specific `LocationSystem`
     * @returns LocationSystem A single `LocationSystem`.
     * @throws ApiError
     */
    public static runLocationSystemFlow({
id,
section,
level,
}: {
/**
 * ID of the `LocationSystem`
 */
id: string,
/**
 * Section of the Flow
 */
section: string,
/**
 * Level of the Section
 */
level: string,
}): CancelablePromise<LocationSystem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/location-systems/{id}/flow/{section}/{level}',
            path: {
                'id': id,
                'section': section,
                'level': level,
            },
        });
    }

}
