import React from "react";
import { Navigate } from "react-router-dom";
import { useCheckAuth } from "../hooks/useCheckAuth";
import { OpenAPI } from "../client";
import { useAuth } from "../hooks/useAuth";
import { usePulse } from "../hooks/usePulse";

export default function SecurityLayout({ children }) {
  const { isAuthenticated, auth } = useAuth();

  OpenAPI.TOKEN = auth?.jwt?.accessToken;
  OpenAPI.WITH_CREDENTIALS = true;

  useCheckAuth();
  usePulse();

  if (isAuthenticated) {
    return <>{children}</>;
  } else {
    return <Navigate to="/login" replace />;
  }
}
