import { darken, lighten } from "@mui/material";

const colors = {
  primary: {
    50: lighten("#eb5e48", 0.9),
    100: lighten("#eb5e48", 0.8),
    200: lighten("#eb5e48", 0.6),
    300: lighten("#eb5e48", 0.4),
    400: lighten("#eb5e48", 0.2),
    500: "#eb5e48",
    600: darken("#eb5e48", 0.2),
    650: darken("#eb5e48", 0.3),
    700: darken("#eb5e48", 0.4),
    800: darken("#eb5e48", 0.6),
    900: darken("#eb5e48", 0.8),
    950: darken("#eb5e48", 0.9),
  },
  secondary: {
    500: "#79ac2b",
  },
  focus: {
    500: "#1976D2",
  },
  brown: {
    1: "#45001d",
  },
  orange: {
    1: "#f7a381",
  },
  speam: {
    1: "#eb5e48",
    2: "#eb5e48a3",
  },
  black: {
    1: "#000000",
  },
  red: {
    1: "#e36262",
    darker: darken("#e36262", 0.2),
    lighter: lighten("#e36262", 0.2),
  },
  blue: {
    1: "#8787cf",
    2: "#4239d1",
    3: "#4d51e6",
  },
  green: {
    1: "#79ac2b",
    2: "#79ac2ba3",
    3: "#79ac2b",
    darker: darken("#79ac2b", 0.2),
    lighter: lighten("#79ac2b", 0.2),
  },
};

export default colors;
