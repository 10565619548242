import { Button, Divider, Flex, Image, Input, ModalFooter, Text, useToast, Skeleton } from "@chakra-ui/react";
import QRCode from "qrcode";
import React, { useEffect, useState } from "react";
import { AuthService } from "../../../client";
import AppModal from "../../AppModal";
import ReceiveRecoveryCodesModal from "./ReceiveRecoveryCodesModal";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";

function Setup2FAModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { auth, setAuth } = useAuth();
  const { t } = useTranslation("authentication");

  const [otpAuthUrl, setOtpAuthUrl] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [qrLoading, setQrLoading] = useState(false);
  const [step, setStep] = useState<"VERIFY_OTP" | "GET_RECOVERY_CODES">("VERIFY_OTP");
  const [recoveryCodes, setRecoveryCodes] = useState<string[]>([]);

  const toast = useToast();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthCode(event.target.value);
  };

  const googleStep = [
    {
      id: 1,
      content: t("installGaOrAuthy"),
    },
    {
      id: 2,
      content: t("selectStarIcon"),
    },
    {
      id: 3,
      content: t("selectScanCode"),
    },
  ];

  const handleWrongToken = () => {
    setIsLoading(false);
    setAuthCode("");
    toast({
      title: t("tokenIsWrong"),
      status: "error",
      duration: 2000,
      isClosable: true,
    });
  };

  const handleVerifyOTP = async () => {
    setIsLoading(true);

    try {
      const { recoveryCodes, ...otpSettings } = await AuthService.otpVerify({ requestBody: { token: authCode } });
      setIsLoading(false);
      setAuth({
        ...auth,
        otp: otpSettings,
      });
      setRecoveryCodes(recoveryCodes);
      setAuthCode("");
      toast({
        title: t("2FAEnabledSuccessfully"),
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      setStep("GET_RECOVERY_CODES");
    } catch (err) {
      handleWrongToken();
    }
  };

  // if six digits are entered, verify OTP:
  useEffect(() => {
    if (authCode.length === 6) {
      handleVerifyOTP();
    }
  }, [authCode]);

  //Generate image QR Code:
  useEffect(() => {
    if (otpAuthUrl) {
      QRCode.toDataURL(otpAuthUrl, function (err, url) {
        url && setQrCode(url);
        setQrLoading(true);
      });
    }
  }, [otpAuthUrl]);

  //Generate QR Code when enable 2FA:
  useEffect(() => {
    const handleQRGenerate = async () => {
      AuthService.otpGenerate()
        .then((data) => {
          if (data) {
            setOtpAuthUrl(data.authUrl);
            setSecretKey(data.secret);
          }
        })
        .catch((error) => {
          let errorMessage = t("common:error.errorOccurred");
          if (error.response && error.response.status === 404) {
            errorMessage = error.response.data.error;
          }
          throw new Error(errorMessage);
        });
    };

    if (open) {
      handleQRGenerate();
    }
  }, [open]);

  const renderModal = () => {
    switch (step) {
      case "VERIFY_OTP":
        return (
          <AppModal open={open} onCloseModal={onClose} title={t("2FATitle")} dividerTitle={true} hasCloseButton={true}>
            <Flex direction="column" width="100%">
              <Flex direction="column">
                <Text variant="2fa-title">{t("ConfiguringGAOrAuthy")}</Text>
                <Divider mt={3} mb={3} />
                <Flex direction="column">
                  {googleStep.map((item, index) => (
                    <Text key={index}>
                      {item.id}. {item.content}
                    </Text>
                  ))}
                </Flex>
              </Flex>

              <Flex direction="column" mt="5">
                <Text variant="2fa-title">{t("ScanQRCode")}</Text>
                <Divider mt={3} mb={3} />
                <Flex align="center" justify="center">
                  <Skeleton isLoaded={qrLoading} bg="green.500">
                    <Image src={qrCode} boxSize={200} alt="qr image" />
                  </Skeleton>
                </Flex>
              </Flex>

              <Flex direction="column" mt="5">
                <Text variant="2fa-title">{t("OrEnterCodeIntoYourApp")}</Text>
                <Divider mt={3} mb={3} />
                <Text>
                  {t("secretKey")}: {secretKey}
                </Text>
              </Flex>

              <Flex direction="column" mt="5">
                <Text variant="2fa-title">{t("verifyCode")}</Text>
                <Divider mt={3} mb={3} />
                <Text>{t("verifyAuthCodeBeforeChange")}</Text>
                <Input
                  variant="unstyled"
                  boxSizing="border-box"
                  h={35}
                  w={280}
                  p="5px 10px"
                  type="text"
                  border="1px solid gray"
                  placeholder={t("authenticationCode")}
                  fontSize={16}
                  mt={3}
                  _focus={{
                    boxShadow: "none",
                    outline: "none",
                    border: "2px solid black",
                  }}
                  onChange={handleInputChange}
                  value={authCode}
                />
              </Flex>
            </Flex>

            <Divider mt={3} mb={3} />
            <ModalFooter display={"flex"} justifyContent={"space-between"} px={0} pt={2}>
              <Button bg="white" color="black" border="1px solid black" mr={3} onClick={onClose}>
                {t("common:close")}
              </Button>
              <Button
                bg="blue.2"
                color="white"
                opacity={isLoading ? "0.8" : 1}
                cursor={isLoading ? "not-allowed" : "pointer"}
                _hover={{
                  opacity: 0.8,
                }}
                onClick={handleVerifyOTP}
              >
                {isLoading ? t("verifying") : t("verify")}
              </Button>
            </ModalFooter>
          </AppModal>
        );
      case "GET_RECOVERY_CODES":
        return (
          <ReceiveRecoveryCodesModal
            open={open}
            onClose={() => {
              setRecoveryCodes([]);
              setStep("VERIFY_OTP");
              onClose();
            }}
            recoveryCodes={recoveryCodes}
          />
        );
    }
  };

  return renderModal();
}

export default Setup2FAModal;
