/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CountService {

    /**
     * Get a count for all entities
     * @returns any Response with status and date
     * @throws ApiError
     */
    public static countAll(): CancelablePromise<{
/**
 * Number of customers
 */
customers?: number;
/**
 * Number of employees
 */
employees?: number;
/**
 * Number of locations
 */
locations?: number;
/**
 * Number of contacts
 */
contacts?: number;
/**
 * Number of services
 */
services?: number;
}> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/count/all',
        });
    }

}
