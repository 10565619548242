/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicAttribute } from '../models/PublicAttribute';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublicAttributeService {

    /**
     * Get a list of `PublicAttribute`s
     * @returns any 
     * @throws ApiError
     */
    public static listPublicAttribute({
page,
size,
filter,
sort,
searchTerm,
}: {
/**
 * Page number
 */
page?: number,
/**
 * Number of items per page
 */
size?: number,
/**
 * A filter to apply to the results. Multiple filters can be applied by using the same parameter name multiple times. String must be URL encoded. String follows the format field,operator,value(s),required. Filters can be applied to associations by using `[associatedModel*].[attribute]` as field. If values is an array, which is allowed for e.g. the operators "in", "notIn" etc, the delimiter "|" must be used. "required" is allowed, if a deep filter (= filter on an association) is set. In this case, only entities that have an associated entity are returned. *model name should be written as in the API Response, meaning with lowercase first letter and if need be in plural. `required` is optional
 */
filter?: string,
/**
 * Sorts the results by the given field and order. The format is field:order. The order can be asc or desc. Multiple fields can be sorted by using a comma separated list.
 */
sort?: string,
/**
 * Seach Term for full text search. Whether supported and which fields are included depends on the model.
 */
searchTerm?: string,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public-attributes',
            query: {
                'page': page,
                'size': size,
                'filter': filter,
                'sort': sort,
                'searchTerm': searchTerm,
            },
        });
    }

    /**
     * Create a new `PublicAttribute`
     * @returns any 
     * @throws ApiError
     */
    public static createPublicAttribute({
requestBody,
}: {
requestBody: PublicAttribute,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/public-attributes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a specific `PublicAttribute`.
     * @returns any 
     * @throws ApiError
     */
    public static getPublicAttribute({
key,
}: {
/**
 * key of the `PublicAttribute`
 */
key: string,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public-attributes/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * Update a specific `PublicAttribute`
     * @returns PublicAttribute A single `PublicAttribute`.
     * @throws ApiError
     */
    public static updatePublicAttribute({
key,
requestBody,
}: {
/**
 * key of the `PublicAttribute`
 */
key: string,
requestBody: Record<string, any>,
}): CancelablePromise<PublicAttribute> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/public-attributes/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a specific `PublicAttribute`
     * @returns void 
     * @throws ApiError
     */
    public static deletePublicAttribute({
key,
}: {
/**
 * key of the `PublicAttribute`
 */
key: string,
}): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/public-attributes/{key}',
            path: {
                'key': key,
            },
        });
    }

}
