/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Settings } from '../models/Settings';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SettingsService {

    /**
     * Get the `Settings` object
     * @returns Settings The `Settings` object
     * @throws ApiError
     */
    public static getSettings(): CancelablePromise<Settings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings',
        });
    }

    /**
     * Update the `Settings` object
     * @returns Settings The `Settings` object
     * @throws ApiError
     */
    public static updateSettings({
requestBody,
}: {
requestBody: Settings,
}): CancelablePromise<Settings> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/settings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
