import { LoginUiConfig, ThemeUiConfig, UiConfig } from "../types/UiConfig.type";

export const initialLoginUiConfig: LoginUiConfig = {
  input: { color: "orange.1", placeholder: { color: "gray" }, backgroundColor: "brown.1" },
  form: { backgroundColor: "rgba(255,255,255,0.1)" },
  mainButton: { color: "white", backgroundColor: "red" },
};

export const initialThemeUiConfig: ThemeUiConfig = {
  typography: { fontSize: "md" },
  colors: {
    primary: "#eb5e48",
    secondary: "#79ac2b",
  },
};

export const initialImagesUiConfig = {
  logoDocumentId: undefined,
  loginBackgroundDocumentId: undefined,
  loginOverlayDocumentId: undefined,
}

export const initialUiConfig: UiConfig = {
  login: initialLoginUiConfig,
  theme: initialThemeUiConfig,
  images: initialImagesUiConfig,
};

