import { Box, Spinner } from "@chakra-ui/react";
import React from "react";

const LoadingSpinner = () => (
  <Box
    sx={{
      // position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spinner size={"lg"} />
  </Box>
);

export default LoadingSpinner;
