// fetch settings from server every 30s
import { useQuery } from "@tanstack/react-query";
import { PublicAttributeService, SettingsService } from "../client";
import { useEffect } from "react";
import { useStore } from "../zustand/store";
import { isEqual } from "lodash";
import i18n from "i18next";

export const usePulse = () => {
  const setUiConfig = useStore((state) => state.setUiConfig);
  const uiConfig = useStore((state) => state.uiConfig);
  const settings = useStore((state) => state.settings);
  const setSettings = useStore((state) => state.setSettings);

  const themeQuery = useQuery({
    queryKey: ["get", "PublicAttribute", "theme"],
    queryFn: () => {
      return PublicAttributeService.getPublicAttribute({
        key: "theme",
      });
    },
    refetchInterval: 30000,
  });

  const imageQuery = useQuery({
    queryKey: ["get", "PublicAttribute", "images"],
    queryFn: () => {
      return PublicAttributeService.getPublicAttribute({
        key: "images",
      });
    },
  });

  const settingsQuery = useQuery({
    queryKey: ["get", "Settings"],
    queryFn: () => {
      return SettingsService.getSettings();
    },
  });

  useEffect(() => {
    let updates: {
      theme?: any;
      images?: any;
    } = {};

    // Check if theme has changed
    if (themeQuery.data && !isEqual(themeQuery.data, uiConfig.theme)) {
      updates.theme = themeQuery.data;
    }

    // Check if images have changed
    if (imageQuery.data && !isEqual(imageQuery.data, uiConfig.images)) {
      updates.images = imageQuery.data;
    }

    // If either theme or images have changed, update the state
    if (Object.keys(updates).length) {
      setUiConfig(updates);
    }
  }, [themeQuery.data, imageQuery.data]);

  useEffect(() => {
    if (settingsQuery.data && !isEqual(settingsQuery.data, settings)) {
      setSettings(settingsQuery.data);
      if (settingsQuery.data.language !== i18n.language) {
        i18n.changeLanguage(settingsQuery.data.language);
      }
    }
  }, [settingsQuery.data]);
};
