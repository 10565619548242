import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { ListResponse, Location, LocationService, SystemModel, SystemModelService } from "../../../../client";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { Grid, GridItem, Image, Text } from "@chakra-ui/react";
import { buildFilterParam } from "../../../../utils/buildFilterParam";

export const SelectSystemModel = ({
  handleSelectSystemModel,
  manufacturerId,
  systemFamilyId,
}: {
  handleSelectSystemModel: (id: string) => void;
  manufacturerId: string;
  systemFamilyId: string;
}) => {
  const { data }: UseQueryResult<ListResponse & { data: SystemModel }, Error> = useQuery({
    queryKey: [
      "list",
      "SystemModel",
      {
        manufacturerId,
        systemFamilyId,
      },
    ],
    queryFn: () =>
      SystemModelService.listSystemModels({
        page: 0,
        size: 100,
        // @ts-ignore
        filter: buildFilterParam([
          {
            field: "manufacturer.id",
            operator: "equals",
            value: manufacturerId,
          },
          {
            field: "systemFamily.id",
            operator: "equals",
            value: systemFamilyId,
          },
        ]),
      }),
  });

  if (!data) {
    return <LoadingSpinner />;
  }

  return (
    <Grid templateColumns="repeat(auto-fill, minmax(192px, 1fr))" gap={4}>
      {data.data.map((systemModel) => (
        <GridItem
          w="48"
          h="48"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.1)",
              transition: "transform 0.5s",
            },
          }}
          onClick={() => {
            handleSelectSystemModel(systemModel.id);
          }}
        >
          <Image src={systemModel.imageUrl} alt={`image of a ${systemModel.name}`} />
          <Text fontSize="md" textAlign={"center"}>
            {systemModel.abbreviation || systemModel.name}
          </Text>
        </GridItem>
      ))}
    </Grid>
  );
};
