import { useStore } from "../zustand/store";
import { ChakraProvider } from "@chakra-ui/react";
import { createChakraUiThemeFromUiConfig } from "../theme";
import React from "react";

// this code was extracted from the general index.file (src/index.js), due to
// problems between react-query and zustand.
// with the general Index component listening to uiConfig (aka zustand store), changes
// would rerender the component, resulting in the react-query queries being dropped.
export const ChakraWrapper = ({ children }: { children: React.ReactNode }) => {
  const uiConfig = useStore((state) => state.uiConfig);

  return <ChakraProvider theme={createChakraUiThemeFromUiConfig(uiConfig)}>{children}</ChakraProvider>;
};
