import React from "react";
import { useStore } from "../zustand/store";

export const useAuth = () => {
  const { auth, setAuth } = useStore();

  const isAuthenticated = !!auth?.jwt?.accessToken;

  return { auth, isAuthenticated, setAuth };
};
