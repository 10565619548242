import { lazy } from "react";
import LocationAddSystemPage from "./pages/Location/pages/LocationAddSystemPage";

// Pages
const Home = lazy(() => import("./pages/Home/Home"));
const Login = lazy(() => import("./pages/Login/Login"));

// Layouts
const InvalidLayout = lazy(() => import("./layouts/InvalidLayout"));

// Employee Pages
const EmployeeListPage = lazy(() => import("./pages/Employee/pages/EmployeeListPage"));
const EmployeeDetailsPage = lazy(() => import("./pages/Employee/pages/EmployeeDetailsPage"));

// Location Pages
const LocationListPage = lazy(() => import("./pages/Location/pages/LocationListPage"));
const LocationDetailsPage = lazy(() => import("./pages/Location/pages/LocationDetailsPage"));
const LocationSystemDetailsPage = lazy(() => import("./pages/Location/pages/LocationSystemDetailsPage"));

// Contact Pages
const ContactListPage = lazy(() => import("./pages/Contact/pages/ContactListPage"));
const ContactDetailsPage = lazy(() => import("./pages/Contact/pages/ContactDetailsPage"));

// Customer Pages
const CustomerListPage = lazy(() => import("./pages/Customer/pages/CustomerListPage"));
const CustomerDetailsPage = lazy(() => import("./pages/Customer/pages/CustomerDetailsPage"));

// Customization Pages
const CustomizationPage = lazy(() => import("./pages/Customization/pages/CustomizationPage"));

const SettingsPage = lazy(() => import("./pages/Settings/pages/SettingsPage"));

export const routePaths = {
  home: "/",
  login: "/login",
  locations: "/locations",
  locationDetails: (locationId) => `/locations/${locationId}`,
  locationAddSystem: (locationId) => `/locations/${locationId}/add-system`,
  locationSystemDetails: (locationId, systemId) => `/locations/${locationId}/systems/${systemId}`,
  employees: "/employees", 
  employeeDetails: (employeeId) => `/employees/${employeeId}`,
  contacts: "/contacts",
  contactDetails: (contactId) => `/contacts/${contactId}`,
  customers: "/customers",
  customerDetails: (customerId) => `/customers/${customerId}`,
  customization: "/customization",
  settings: "/settings",
};

const publicRoutes = [
  { path: routePaths.login, component: Login, layout: Login },
  { path: "*", component: Home, layout: InvalidLayout },
];

const privateRoutes = [
  { path: "/", component: Home },
  { path: routePaths.locations, component: LocationListPage },
  {
    path: routePaths.locationDetails(":id"),
    component: LocationDetailsPage,
  },
  {
    path: routePaths.locationAddSystem(":id"),
    component: LocationAddSystemPage,
  },
  {
    path: routePaths.locationSystemDetails(":id",":systemId"),
    component: LocationSystemDetailsPage,
  },
  { path: routePaths.employees, component: EmployeeListPage },
  {
    path: routePaths.employeeDetails(":id"),
    component: EmployeeDetailsPage,
  },
  { path: routePaths.contacts, component: ContactListPage },
  {
    path: routePaths.contactDetails(":id"),
    component: ContactDetailsPage,
  },
  { path: routePaths.customers, component: CustomerListPage },
  {
    path: routePaths.customerDetails(":id"),
    component: CustomerDetailsPage,
  },
  // customizations
  { path: routePaths.customization, component: CustomizationPage },
  { path: routePaths.settings, component: SettingsPage },
];

export { publicRoutes, privateRoutes };
