import React, { ReactNode } from "react";
import { Divider, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from "@chakra-ui/react";

interface AppModalProps {
  open: boolean;
  onCloseModal: () => void;
  children: ReactNode;
  title: string;
  dividerTitle?: boolean;
  hasCloseButton?: boolean;
  closeOnOverlayClick?: boolean;
  size?: string;
}

function AppModal({
  open,
  onCloseModal,
  children,
  title,
  dividerTitle,
  hasCloseButton,
  closeOnOverlayClick = true,
  size = "md",
}: AppModalProps) {
  return (
    <Modal
      closeOnOverlayClick={closeOnOverlayClick}
      scrollBehavior="inside"
      blockScrollOnMount={false}
      isCentered
      isOpen={open}
      onClose={onCloseModal}
      size={size}
    >
      <ModalOverlay />
      <ModalContent>
        {title && (
          <ModalHeader>
            {title}
            {dividerTitle ? <Divider mt={3} /> : null}
          </ModalHeader>
        )}
        {hasCloseButton ? <ModalCloseButton /> : null}
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default AppModal;
