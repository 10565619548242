const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    primary: {
      backgroundColor: "primary.500",
      color: "white",
      minWidth: "120px",
      "&:hover": {
        backgroundColor: "primary.600",
        boxShadow: "md",
      },
      "&:disabled": {
        "&:hover": {
          backgroundColor: "primary.500",
        },
      },
    },
    secondary: {
      border: "1px solid",
      borderColor: "primary.500",
      minWidth: "120px",
      "&:hover": {
        backgroundColor: "primary.50",
        boxShadow: "sm",
      },
    },
    redPrimary: {
      backgroundColor: "red.500",
      color: "white",
      minWidth: "120px",
      "&:hover": {
        backgroundColor: "red.600",
        boxShadow: "md",
      },
    },
    redSecondary: {
      border: "1px solid",
      borderColor: "red.500",
      minWidth: "120px",
      "&:hover": {
        backgroundColor: "red.50",
        boxShadow: "sm",
      },
    },
    "login-btn": {
      size: "lg",
      width: "full",
      bg: "brown.1",
      color: "orange.1",
      textTransform: "uppercase",
      borderRadius: "10px",
    },
    "authenticate-btn": {
      size: "lg",
      width: "315px",
      bg: "brown.1",
      color: "orange.1",
      textTransform: "uppercase",
      borderRadius: "10px",
      mt: "15px",
    },
  },
};

export default Button;
