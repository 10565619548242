import { useQuery } from "@tanstack/react-query";
import { EmployeeService, StatusService } from "../client";
import React, { useEffect, useState } from "react";
import { Box, Container, Heading, Image, Flex, Text, Badge, Spinner } from "@chakra-ui/react";
// @ts-ignore
import logo_colored from "../resources/svg/logo_colored.svg";
import { Disable2FAModal } from "../components/Auth/2FA/Disable2FAModal";
import Setup2FAModal from "../components/Auth/2FA/Setup2FAModal";
import { useStore } from "../zustand/store";
import { useAuth } from "../hooks/useAuth";
import { getImageUrlFromDocumentId } from "../utils/getImageUrlFromDocument";
import { Skeleton } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

export default function DefaultLayout({ children }: { children: React.ReactNode }) {
  const employeeQuery = useQuery(["get", "Employee", "Self"], () => EmployeeService.getSelf());
  const statusQuery = useQuery(["get", "Status"], () => StatusService.getStatus(), {});

  const { auth } = useAuth();
  const { t } = useTranslation();
  const reset = useStore((state) => state.reset);
  const uiConfig = useStore((state) => state.uiConfig);

  const logoDocumentId = uiConfig?.images?.logoDocumentId;

  let otpEnabled, otpVerified;
  if (auth?.otp) {
    ({ enabled: otpEnabled, verified: otpVerified } = auth.otp);
  }

  const [isSetup2FAModalOpen, setIsSetup2FAModalOpen] = useState(false);
  const [isDisable2FAModalOpen, setIsDisable2FAModalOpen] = useState(false);

  // fallback to cached data
  const employeeData = employeeQuery.data || auth?.employee;

  function isCurrentLocationHome() {
    return window.location.pathname === "/";
  }

  //TODO (AH): add function to get the current page title
  //TODO (AH): add breadcrumbs

  return (
    <Flex direction="column" minH="100vh">
      <Container maxW="container.xl" flex="1">
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={6}>
          <Heading fontWeight="light" size={{ base: "md", md: "xl" }}>
            {isCurrentLocationHome() && (
              <>
                <Typography variant="h2" gutterBottom>
                  Home
                </Typography>
                {employeeData && (
                  <Typography variant="h5">
                    {t("Welcome back")}, {employeeData.name}!
                  </Typography>
                )}
              </>
            )}

            {/*Welcome {employeeData ? employeeData.name : <Spinner size="lg" />}*/}
          </Heading>
          <Box>
            <Flex direction="column" alignItems="flex-end">
              <Box display="flex" alignItems="center">
                {!getImageUrlFromDocumentId(logoDocumentId) /*Ohne das ! vorne*/ ? (
                  <Image
                    src="/temp/Speam BOX.svg" //{getImageUrlFromDocumentId(logoDocumentId)}
                    alt="Logo"
                    w="300px"
                    // maxH={{ base: "50px", md: "100px" }}
                    h={"75px"}
                    objectFit="contain"
                    mb={"8px"}
                    mt={"8px"}
                  />
                ) : (
                  <Skeleton height="75px" />
                )}
              </Box>
              <Flex direction="row" alignItems="flex-end">
                <Box display="flex" alignItems="flex-end" ml={4}>
                  <Badge
                    colorScheme={otpEnabled ? "red" : "green"}
                    fontWeight="bold"
                    cursor="pointer"
                    mr={2}
                    px={2}
                    py={1}
                    borderRadius="md"
                    onClick={!otpEnabled ? () => setIsSetup2FAModalOpen(true) : () => setIsDisable2FAModalOpen(true)}
                  >
                    <Flex alignItems="center">{otpEnabled ? t("disable2FA") : t("enable2FA")}</Flex>
                  </Badge>
                </Box>
                <Box display="flex" alignItems="flex-end" ml={4}>
                  <Badge
                    colorScheme={"red"}
                    fontWeight="bold"
                    cursor="pointer"
                    mr={2}
                    px={2}
                    py={1}
                    borderRadius="md"
                    onClick={() => reset()}
                  >
                    <Flex alignItems="center">{t("logout")}</Flex>
                  </Badge>
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Box>
        {children}
        <Box>
          <Setup2FAModal open={isSetup2FAModalOpen} onClose={() => setIsSetup2FAModalOpen(false)} />
          <Disable2FAModal isOpen={isDisable2FAModalOpen} onClose={() => setIsDisable2FAModalOpen(false)} />
        </Box>
      </Container>
      <Flex
        position="relative"
        bottom={0}
        right={0}
        p={2}
        justify={{ base: "center", md: "flex-end" }}
        alignItems={{ base: "center", md: "flex-start" }}
      >
        {statusQuery.data ? (
          <Text color="#909193" fontWeight="light">
            {t("version")} {statusQuery.data.version}
          </Text>
        ) : (
          <Box display="flex" alignItems="center">
            <Text color="#909193" fontWeight="light">
              {t("version")}
            </Text>
            <Spinner color="#909193" size="md" ml={1} />
          </Box>
        )}
      </Flex>
    </Flex>
  );
}
