import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { ListResponse, Location, LocationService, SystemFamily, SystemFamilyService } from "../../../../client";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { Grid, GridItem, Image, Text } from "@chakra-ui/react";

export const SelectSystemFamily = ({
  handleSelectSystemFamily,
}: {
  handleSelectSystemFamily: (systemFamily: SystemFamily) => void;
}) => {
  const { data }: UseQueryResult<ListResponse & { data: SystemFamily }, Error> = useQuery({
    queryKey: ["list", "SystemFamily"],
    queryFn: () =>
      SystemFamilyService.listSystemFamilies({
        page: 0,
        size: 20,
      }),
  });

  if (!data) {
    return <LoadingSpinner />;
  }

  return (
    <Grid templateColumns="repeat(auto-fill, minmax(192px, 1fr))" gap={4}>
      {data.data.map((systemFamily) => (
        <GridItem
          w="48"
          h="48"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            cursor: "pointer",
            "&:hover": {
              transform: "scale(1.1)",
              transition: "transform 0.5s",
            },
          }}
          onClick={() => {
            handleSelectSystemFamily(systemFamily);
          }}
        >
          <Image src={systemFamily.imageUrl} alt={""} />
          <Text fontSize="md" textAlign={"center"}>
            {systemFamily.abbreviation || systemFamily.name}
          </Text>
        </GridItem>
      ))}
    </Grid>
  );
};
