import React, { Fragment, Suspense, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { v4 as uuid } from "uuid";
import reportWebVitals from "./reportWebVitals";
import { privateRoutes, publicRoutes } from "./routes";
import DefaultLayout from "./layouts/DefaultLayout";
import SecurityLayout from "./layouts/SecurityLayout";
import { Box, ChakraProvider, Spinner, useToast } from "@chakra-ui/react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { PreviousStateProvider } from "./contexts/PreviousStateContext";
import Fonts from "./theme/foundations/fonts";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LicenseInfo } from "@mui/x-license-pro";
import { OpenAPI } from "./client";
import LoadingSpinner from "./components/LoadingSpinner";
import { MutationCache } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ChakraWrapper } from "./contexts/ChakraContext";
import "./config/i18next";

import createTheme from "../src/pages/Home/theme/index";
import useMuiTheme from "../src/pages/Home/useTheme.ts";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

export default function App() {
  const toast = useToast();
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);
  OpenAPI.BASE = process.env.REACT_APP_API_URL;

  const snackError = (error) => {
    if (error?.response?.data?.message) {
      toast({
        title: "Error",
        description: error?.response?.data?.message || "Unknown error",
        status: "error",
      });
      return;
    }

    if (!error?.response?.status) {
      toast({
        title: "Error",
        description: "Unknown error",
        status: "error",
      });
      return;
    }

    switch (error?.response.status) {
      case 401:
        toast({
          title: "Error",
          description: "Missing permission",
          status: "error",
        });
        break;

      case 403:
        toast({
          title: "Error",
          description: "Action not allowed",
          status: "error",
        });
        break;

      case 404:
        toast({
          title: "Error",
          description: "Not found",
          status: "error",
        });
        break;

      case 413:
        toast({
          title: "Error",
          description: "File too large",
          status: "error",
        });
        break;

      case 500:
        toast({
          title: "Error",
          description: "Internal server error",
          status: "error",
        });
        break;

      case 503:
        toast({
          title: "Error",
          description: "Service unavailable",
          status: "error",
        });
        break;

      default:
        toast({
          title: "Error",
          description: "Unknown error",
          status: "error",
        });
        break;
    }
  };

  const mutationCache = new MutationCache({
    onError: (error) => {},
    onSuccess: (data, variables, context, mutation) => {
      const meta = mutation.options.meta;
      toast({
        title: meta?.toastTitle || "Success",
        description: meta?.toastDescription || "Changes saved successfully",
        status: "success",
        position: "bottom-left",
      });
    },
  });

  const queryClient = new QueryClient({
    mutationCache,
    defaultOptions: {
      mutations: {
        onError: (error) => {
          snackError(error);
        },
      },
      useErrorBoundary: true,
    },
  });

  const muiTheme = useMuiTheme();

  return (
    <MuiThemeProvider theme={createTheme(muiTheme)}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          {process.env.REACT_APP_NODE_ENV === "development" ? <ReactQueryDevtools initialIsOpen={false} /> : ""}
          <ChakraWrapper>
            <Fonts />
            <PreviousStateProvider>
              <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                  {privateRoutes.map((route) => {
                    const Page = route.component;
                    let Layout = DefaultLayout;

                    if (route.layout === null) {
                      Layout = Fragment;
                    } else if (route.layout) {
                      Layout = route.layout;
                    }

                    return (
                      <Route
                        key={uuid()}
                        path={route.path}
                        element={
                          <SecurityLayout>
                            <Layout>
                              <Page />
                            </Layout>
                          </SecurityLayout>
                        }
                      />
                    );
                  })}

                  {publicRoutes.map((route) => {
                    const Page = route.component;
                    let Layout = DefaultLayout;

                    if (route.layout === null) {
                      Layout = Fragment;
                    } else if (route.layout) {
                      Layout = route.layout;
                    }

                    return (
                      <Route
                        key={uuid()}
                        path={route.path}
                        element={
                          <Layout>
                            <Page />
                          </Layout>
                        }
                      />
                    );
                  })}
                </Routes>
              </Suspense>
            </PreviousStateProvider>
          </ChakraWrapper>
        </QueryClientProvider>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

reportWebVitals(console.log);
