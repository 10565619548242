import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// json TS loading seems buggy https://github.com/TypeStrong/ts-loader/issues/905
// @ts-ignore
import enUSCommon from "../locales/en-US/common.locale.json";
// @ts-ignore
import enUSAuthentication from "../locales/en-US/authentication.locale.json";
// @ts-ignore
import deDECommon from "../locales/de-DE/common.locale.json";
// @ts-ignore
import deDEAuthentication from "../locales/de-DE/authentication.locale.json";
// @ts-ignore
import enUSCustomization from "../locales/en-US/customization.locale.json";
// @ts-ignore
import enUSProduct from "../locales/en-US/product.locale.json";
// @ts-ignore
import enUSChecklist from "../locales/en-US/checklist.locale.json";
// @ts-ignore
import enUSTime from "../locales/en-US/time.locale.json";
// @ts-ignore
import enUSSystem from "../locales/en-US/system.locale.json";
// @ts-ignore
import deDECustomization from "../locales/de-DE/customization.locale.json";
// @ts-ignore
import deDEProduct from "../locales/de-DE/product.locale.json";
// @ts-ignore
import deDEChecklist from "../locales/de-DE/checklist.locale.json";
// @ts-ignore
import deDETime from "../locales/de-DE/time.locale.json";
import { useStore } from "../zustand/store";

i18n
  .use(initReactI18next)
  // @ts-ignore
  .init({
    // tried loading dynamically with i18next-resources-to-backend, but it did not work
    resources: {
      "en-US": {
        common: enUSCommon,
        authentication: enUSAuthentication,
        customization: enUSCustomization,
        product: enUSProduct,
        checklist: enUSChecklist,
        time: enUSTime,
        system: enUSSystem,
      },
      "de-DE": {
        common: deDECommon,
        authentication: deDEAuthentication,
        customization: deDECustomization,
        product: deDEProduct,
        checklist: deDEChecklist,
        time: deDETime,
      },
    },
    debug: process.env.REACT_APP_NODE_ENV === "development",
    lng: useStore?.getState()?.settings?.language || "en-US",
    fallbackLng: "en-US",
    ns: ["common", "authentication", "customization", "product", "checklist", "time"],
    defaultNS: "common",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
