import { EmployeeService } from "../client";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../zustand/store";
import { useAuth } from "./useAuth";
import { routePaths } from "../routes";

export const useCheckAuth = () => {
  const { auth, setAuth } = useAuth();
  const reset = useStore((state) => state.reset);
  const location = useLocation();
  const navigate = useNavigate();

  const hasValidAuth = async () => {
    try {
      const employee = await EmployeeService.getSelf();
      setAuth({ ...auth, employee });
    } catch (err) {
      // do not log out if the error is due to invalid refresh token or
      // network error

      if (
        // @ts-ignore
        err?.response?.status === 401 &&
        // @ts-ignore
        (err?.response?.data?.message !== "Invalid refresh token" || err?.code !== "ERR_NETWORK")
      ) {
        navigate(routePaths.login + "?message=sessionExpired");
        reset();

        return false;
      }
      return false;
    }

    return true;
  };

  useEffect(() => {
    hasValidAuth();
  }, [location]);
};
