/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthBundle } from '../models/AuthBundle';
import type { Employee } from '../models/Employee';
import type { OtpSettings } from '../models/OtpSettings';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Register a new Employee
     * Register an `Employee` with a name, email, password, and optional status. Password must be a minimum of 6 characters, and include at least one letter, one number, and one special character.
     * @returns AuthBundle Successfully registered or logged in.
     * @throws ApiError
     */
    public static register({
requestBody,
}: {
requestBody: (Employee & {
/**
 * The user's password.
 */
password: string;
}),
}): CancelablePromise<AuthBundle> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Log in a employee
     * Log in a `Employee` with email and password.
     * @returns AuthBundle Successfully registered or logged in.
     * @throws ApiError
     */
    public static login({
requestBody,
}: {
requestBody: {
/**
 * The user's email.
 */
email: string;
/**
 * The user's password.
 */
password: string;
/**
 * OTP token.
 */
token?: string;
},
}): CancelablePromise<AuthBundle> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                303: `Redirect to OTP verification.`,
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Refresh the auth token for an Employee.
     * Pass an email and a refresh token in the body to receive a new auth token.
     * @returns AuthBundle Successfully registered or logged in.
     * @throws ApiError
     */
    public static refreshToken({
requestBody,
}: {
requestBody: {
/**
 * The email of the employee.
 */
email: string;
/**
 * The valid refresh token of the employee.
 */
refreshToken: string;
/**
 * The id of the employee.
 */
id: string;
},
}): CancelablePromise<AuthBundle> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/refresh-token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid email or refresh token provided.`,
                401: `Unauthorized. Refresh token is expired or invalid.`,
                500: `Unexpected error.`,
            },
        });
    }

    /**
     * Generate a One Time Password (OTP) to enable 2FA
     * @returns any OTP generated successfully
     * @throws ApiError
     */
    public static otpGenerate(): CancelablePromise<{
/**
 * The OTP auth URL.
 */
authUrl: string;
/**
 * The OTP secret.
 */
secret: string;
}> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/otp/generate',
            errors: {
                400: `Bad request, the request was not formatted correctly`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Verify a One Time Password (OTP) for 2FA
     * @returns any Two-Factor Authentication (2FA) verified successfully.
     * @throws ApiError
     */
    public static otpVerify({
requestBody,
}: {
requestBody: {
/**
 * The One Time Password (OTP) token.
 */
token: string;
},
}): CancelablePromise<(OtpSettings & {
recoveryCodes: Array<string>;
})> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/otp/verify',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request, the request was not formatted correctly`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Disable Two-Factor Authentication (2FA)
     * @returns OtpSettings Two-Factor Authentication (2FA) disabled successfully.
     * @throws ApiError
     */
    public static otpDisable({
requestBody,
}: {
requestBody: {
/**
 * The user's password.
 */
password: string;
},
}): CancelablePromise<OtpSettings> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/otp/disable',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request, the request was not formatted correctly`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Enable Two-Factor Authentication (2FA)
     * @returns OtpSettings Two-Factor Authentication (2FA) enabled successfully.
     * @throws ApiError
     */
    public static otpEnable(): CancelablePromise<OtpSettings> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/otp/enable',
            errors: {
                400: `Bad request, the request was not formatted correctly`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Validate Two-Factor Authentication (2FA) token
     * @returns any Two-Factor Authentication (2FA) token validated successfully
     * @throws ApiError
     */
    public static otpValidate({
requestBody,
}: {
requestBody: {
/**
 * The user's email.
 */
email: string;
/**
 * The user's password.
 */
password: string;
/**
 * The Two-Factor Authentication (2FA) code.
 */
token: string;
},
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/otp/validate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request, the request was not formatted correctly`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Two-Factor Authentication (2FA) Recovery Codes
     * @returns any Successful response
     * @throws ApiError
     */
    public static getOtpRecoveryCodes(): CancelablePromise<{
recoveryCodes?: Array<string>;
}> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth/otp/recovery',
        });
    }

    /**
     * Validate Two-Factor Authentication (2FA) Recovery Code
     * @returns AuthBundle Successfully registered or logged in.
     * @throws ApiError
     */
    public static validateOtpRecoveryCode({
requestBody,
}: {
requestBody: {
/**
 * The user's password.
 */
password: string;
/**
 * The user's email.
 */
email: string;
/**
 * The Two-Factor Authentication (2FA) recovery code.
 */
recoveryCode: string;
},
}): CancelablePromise<AuthBundle> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/otp/recovery',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request, the request was not formatted correctly`,
                500: `Internal Server Error`,
            },
        });
    }

}
