import { GridFilterItem } from "@mui/x-data-grid-pro";

export const buildFilterParam = (
  filters: (GridFilterItem & {
    // only for deep filters: Loads the entity only, if the associatied entity is not null
    required?: true;
  })[]
) => {
  return filters.map((filter) => {
    const { field, operator, value, required } = filter;
    const query = `${field},${operator},${Array.isArray(value) ? value.join("|") : value}, ${required ?? false}`;
    return query;
  });
};
