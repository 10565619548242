import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { SelectSystemFamily } from "../components/LocationAddSystems/SelectSystemFamily";
import React, { useState } from "react";
import { SelectManufacturer } from "../components/LocationAddSystems/SelectManufacturer";
import { SelectSystemModel } from "../components/LocationAddSystems/SelectSystemModel";
import { DisplayInstallationGuide } from "../components/LocationAddSystems/DisplayInstallationGuide";
import { SystemFamily } from "../../../client";
import { DetailsLayout } from "src/layouts/PageLayout";

const LocationAddSystemPage = () => {
  const { t } = useTranslation();

  const steps = [
    { title: t("system:systemFamily"), description: t("step1") },
    { title: t("manufacturer"), description: t("step2") },
    { title: t("system:systemModel"), description: t("step3") },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const [selectedSystemFamily, setSelectedSystemFamily] = useState<SystemFamily | undefined>(undefined);
  const [selectedManufacturer, setSelectedManufacturer] = useState<string | undefined>(undefined);
  const [selectedSystemModel, setSelectedSystemModel] = useState<string | undefined>(undefined);

  const handleSelectSystemFamily = (systemFamily: SystemFamily) => {
    setSelectedSystemFamily(systemFamily);
    if (systemFamily.name === "Sonstige") {
      setActiveStep(3);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleSelectManufacturer = (id: string) => {
    setSelectedManufacturer(id);
    setActiveStep(activeStep + 1);
  };

  const handleSelectSystemModel = (id: string) => {
    setSelectedSystemModel(id);
    setActiveStep(activeStep + 1);
  };

  const renderContent = () => {
    switch (activeStep) {
      case 0:
        return <SelectSystemFamily handleSelectSystemFamily={handleSelectSystemFamily} />;
      case 1:
        return (
          <SelectManufacturer
            handleSelectManufacturer={handleSelectManufacturer}
            systemFamilyId={selectedSystemFamily?.id as string}
          />
        );
      case 2:
        return (
          <SelectSystemModel
            handleSelectSystemModel={handleSelectSystemModel}
            manufacturerId={selectedManufacturer as string}
            systemFamilyId={selectedSystemFamily?.id as string}
          />
        );

      case 3:
        return (
          <DisplayInstallationGuide
            manufacturerId={selectedManufacturer as string}
            systemModelId={selectedSystemModel as string}
          />
        );

      default:
        return null;
    }
  };

  return (
    <DetailsLayout redirectPath={"/locations"}>
      <Stepper index={activeStep} mb={6}>
        {steps.map((step, index) => (
          <Step key={index} onClick={() => setActiveStep(index)}>
            <StepIndicator>
              <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
            </StepIndicator>

            <Box
              flexShrink="0"
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color: "primary.500",
                },
              }}
            >
              <StepDescription
                style={{
                  fontSize: "1rem",
                  textTransform: "uppercase",
                }}
              >
                {step.description}
              </StepDescription>
              <StepTitle
                style={{
                  fontSize: "1.5rem",
                  marginTop: "-8px",
                  paddingBottom: "8px",
                }}
              >
                {step.title}
              </StepTitle>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>

      {renderContent()}
    </DetailsLayout>
  );
};

export default LocationAddSystemPage;
