import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {
  ListResponse,
  Location,
  LocationService,
  Manufacturer,
  ManufacturerService,
  SystemModel,
  SystemModelService,
} from "../../../../client";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { Checkbox, Grid, GridItem, Image, Stack, Text } from "@chakra-ui/react";

export const DisplayInstallationGuide = ({
  manufacturerId,
  systemModelId,
}: {
  manufacturerId: string;
  systemModelId: string;
}) => {
  const manufacturerQuery: UseQueryResult<Manufacturer, Error> = useQuery({
    queryKey: [
      "get",
      "Manufacturer",
      {
        manufacturerId,
      },
    ],
    enabled: !!manufacturerId,
    queryFn: () => ManufacturerService.getManufacturer({ id: manufacturerId }),
  });

  const systemModelQuery: UseQueryResult<SystemModel, Error> = useQuery({
    queryKey: [
      "get",
      "SystemModel",
      {
        systemModelId,
      },
    ],
    enabled: !!systemModelId,
    queryFn: () => SystemModelService.getSystemModel({ id: systemModelId }),
  });

  if (systemModelId && manufacturerId && (!systemModelQuery.data || !manufacturerQuery.data)) {
    return <LoadingSpinner />;
  }

  return (
    <Stack>
      {!systemModelId || !manufacturerId ? (
        <p>Hier folgt die Anleitung zur Installation einer sonstigen Anlage</p>
      ) : (
        <p>
          (Hier folgt die Anleitung für die {systemModelQuery?.data?.name} Anlage des Herstellers{" "}
          {manufacturerQuery?.data?.name})
        </p>
      )}

      <Checkbox defaultChecked>Schritt 1</Checkbox>
      <Checkbox>Schritt 2</Checkbox>
      <Checkbox>Schritt 3</Checkbox>
    </Stack>
  );
};
