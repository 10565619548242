const Text = {
  baseStyle: {},
  sizes: {},
  variants: {
    "2fa-title": {
      color: "blue.1",
      fontWeight: "600",
    },
  },
};

export default Text;
