import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { ListResponse, Location, LocationService, Manufacturer, ManufacturerService } from "../../../../client";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { Grid, GridItem, Image, Text } from "@chakra-ui/react";

export const SelectManufacturer = ({
  systemFamilyId,
  handleSelectManufacturer,
}: {
  systemFamilyId: string;
  handleSelectManufacturer: (id: string) => void;
}) => {
  const { data }: UseQueryResult<ListResponse & { data: Manufacturer }, Error> = useQuery({
    queryKey: [
      "list",
      "Manufacturer",
      {
        sytemFamilyId: systemFamilyId,
      },
    ],
    queryFn: () =>
      ManufacturerService.listManufacturersWithSystemFamily({
        systemFamilyId,
      }),
  });

  if (!data) {
    return <LoadingSpinner />;
  }

  return (
    <Grid templateColumns="repeat(auto-fill, minmax(192px, 1fr))" gap={4}>
      {data.data.map((manufacturer) => (
        <GridItem
          w="48"
          h="48"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            cursor: "pointer",
            "&:hover": {
              // transform: "scale(1.1)",
              // transition: "transform 0.5s",
            },
          }}
          onClick={() => {
            handleSelectManufacturer(manufacturer.id);
          }}
        >
          <Image
            src={manufacturer.logoUrl}
            alt={`${manufacturer.name} Logo`}
            sx={{
              filter: "grayscale(80%)",
              transition: "filter 0.1s",

              "&:hover": {
                filter: "grayscale(0%)",
              },
            }}
          />
          <Text fontSize="lg" textAlign={"center"} fontWeight={500}>
            {manufacturer.name}
          </Text>
        </GridItem>
      ))}
    </Grid>
  );
};
