import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { AuthService } from "../../../client";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";

export const Disable2FAModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const { auth, setAuth } = useAuth();
  const [password, setPassword] = React.useState("");

  const { t } = useTranslation("authentication");

  const disable2FAMutation = useMutation({
    mutationFn: (password: string) => AuthService.otpDisable({ requestBody: { password } }),
    onSuccess: (data) => {
      setAuth({ ...auth, otp: data });
      setPassword("");
      onClose();
    },
    meta: {
      toastDescription: t("2FAHasBeenDisabled"),
    },
  });

  const handleDisable2FA = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    disable2FAMutation.mutate(password);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("disable2FA")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody gap={2} display={"flex"} flexDir={"column"} px={6} pb={4}>
          {t("toDisable2FAEnterPassword")}
          {/*@ts-ignore*/}
          <form onSubmit={handleDisable2FA}>
            <Flex flexDir={"column"} gap={4}>
              <Input
                name={"password"}
                type={"password"}
                placeholder="Password"
                value={password}
                // @ts-ignore
                onChange={(e) => setPassword(e.target.value)}
              />

              <Flex flexDir={"row"} gap={2} justifyContent={"space-between"}>
                <Button colorScheme="gray" mr={3} onClick={onClose}>
                  {t("common:close")}
                </Button>
                <Button type={"submit"} colorScheme={"red"} isDisabled={disable2FAMutation.isLoading}>
                  {disable2FAMutation.isLoading ? t("common:disabling") + "..." : t("common:confirm")}
                </Button>
              </Flex>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
