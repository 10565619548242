import { Button, Flex, ModalFooter, Text, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { HiOutlineDownload } from "react-icons/hi";
// @ts-ignore
import AppModal from "../../AppModal";
// @ts-ignore
import { AuthService } from "../../../client";
// @ts-ignore
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";

function downloadArrayAsTxt(array, filename) {
  const content = array.join("\n");
  const element = document.createElement("a");
  element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(content));
  element.setAttribute("download", filename + ".txt");
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

function ReceiveRecoveryCodesModal({ open, onClose, recoveryCodes }) {
  const toast = useToast();
  const { auth, setAuth } = useAuth();
  const { t } = useTranslation("authentication");

  const [isDownload, setIsDownload] = useState(false);

  const handleDownload = async () => {
    downloadArrayAsTxt(recoveryCodes, "recovery-codes");
    setIsDownload(true);
    toast({
      title: t("downloadSuccess"),
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const handleNext = async () => {
    if (!isDownload) {
      toast({
        title: t("youMustDownloadAndSave"),
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      try {
        const otpSettings = await AuthService.otpEnable();
        setAuth({ ...auth, otp: otpSettings });
        toast({
          title: t("setup2FASuccess"),
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: t("setup2FAError"),
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }

      onClose();
    }
  };

  if (!recoveryCodes) return <></>;

  return (
    <AppModal
      closeOnOverlayClick={false}
      open={open}
      onCloseModal={onClose}
      title={t("downloadRecoveryCodes")}
      dividerTitle={true}
      hasCloseButton={true}
      size={"lg"}
    >
      <Flex direction="column" width="100%" gap={2}>
        <Text>{t("recoveryCodesExplanation")}</Text>

        <Flex flexWrap="wrap" justifyContent="space-between" bg="#e2e2e2" p="20px">
          {recoveryCodes.map((code, index) => (
            <div key={index} style={{ whiteSpace: "nowrap", margin: "12px 8px", fontWeight: 600 }}>
              {code}
            </div>
          ))}
        </Flex>

        <Button
          w="150px"
          alignSelf="flex-end"
          mt="10px"
          color="white"
          bg="green"
          _hover={{
            opacity: 0.8,
          }}
          onClick={handleDownload}
        >
          <HiOutlineDownload color="white" /> {t("download")}
        </Button>
      </Flex>

      <ModalFooter mr="-24px">
        <Button
          bg="green"
          color="white"
          isDisabled={!isDownload}
          onClick={handleNext}
          sx={{
            "&:disabled": {
              cursor: "not-allowed",
              opacity: "0.5 !important",
              bg: "green !important",
            },

            "&:hover": {
              opacity: "0.8",
              bg: "green",
            },
          }}
        >
          {t("iSavedMyRecoveryCodes")}
        </Button>
      </ModalFooter>
    </AppModal>
  );
}

export default ReceiveRecoveryCodesModal;
