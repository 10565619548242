import { Box, Divider, Flex, IconButton, Progress } from "@chakra-ui/react";
import { AiOutlineHome } from "react-icons/ai";
import { IoReturnUpBackSharp } from "react-icons/io5";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIsLoading } from "../hooks/useIsLoading";
import { useTranslation } from "react-i18next";

export const DetailsLayout = ({
  redirectPath,
  children,
  switchElement,
}: {
  redirectPath?: string;
  children: React.ReactNode;
  switchElement?: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading } = useIsLoading();
  const { t } = useTranslation();

  return (
    <>
      <Flex justifyContent={"space-between"}>
        <Box>
          {redirectPath && (
            <IconButton
              variant="ghost"
              bg="transparent"
              _hover={{ bg: "transparent" }}
              onClick={() => {
                navigate(`${redirectPath}?${location.search}`);
              }}
              icon={<IoReturnUpBackSharp />}
              aria-label={t("goBack")}
              fontSize="3xl"
              size="xl"
              mr={4}
            /> 
          )}
          <IconButton
            variant="ghost"
            bg="transparent"
            _hover={{ bg: "transparent" }}
            onClick={() => navigate("/")}
            icon={<AiOutlineHome />}
            aria-label={t("goToHomePage")}
            fontSize="3xl"
            size="xl"
          />
        </Box>

        <Box />
        <Box>{switchElement}</Box>
      </Flex>

      <Divider mt={3} mb={0} />
      {isLoading ? (
        <Progress size="xs" isIndeterminate mb={3} colorScheme={"primary"} />
      ) : (
        <Box mb={3} sx={{ height: "4px" }} />
      )}
      <div>{children}</div>
    </>
  );
};

export const HomeLayout = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <IconButton
        variant="ghost"
        bg="transparent"
        _hover={{ bg: "transparent" }}
        onClick={() => navigate("/")}
        icon={<AiOutlineHome />}
        aria-label={t("goToHomePage")}
        fontSize="3xl"
        size="xl"
      />
      <Divider mt={3} mb={3} />
      {children}
    </>
  );
};
